import { SectionConnectionTypes, SectionFieldType, SectionType, SectionTypeOption } from './types';

export const SECTION_TYPES: SectionTypeOption[] = [
  {
    value: SectionType.Articles,
    label: 'Articles',
    hasCategories: true,
    slug: 'articles',
  },
  { value: SectionType.Subsections, label: 'Subsection', hasCategories: true, slug: 'subsection' },
  {
    value: SectionType.Pages,
    label: 'Pages',
    hasCategories: false,
    slug: 'pages',
  },
  {
    value: SectionType.Connections,
    label: 'Connections',
    hasCategories: false,
    slug: 'connections',
  },
  {
    value: SectionType.Categories,
    label: 'Categories',
    hasCategories: false,
    slug: 'categories',
  },
  {
    value: SectionType.Collections,
    label: 'Collections',
    hasCategories: false,
    slug: 'collections',
  },
];

export const SECTION_TYPES_MAP = SECTION_TYPES.reduce<{
  [key: number]: string;
}>((map, type) => {
  map[type.value] = type.slug;
  return map;
}, {});

export const SECTION_TYPE_ICONS = {
  [SectionType.Articles]: 'fal fa-newspaper',
  [SectionType.Subsections]: 'fal fa-database',
  [SectionType.Pages]: 'fal fa-copy',
  [SectionType.Connections]: 'fal fa-database',
  [SectionType.Categories]: 'fal fa-sitemap',
  [SectionType.Collections]: 'fal fa-sitemap',
};

export const SECTION_CONNECTION_TYPES = [
  {
    value: SectionConnectionTypes.Custom,
    label: SectionConnectionTypes[SectionConnectionTypes.Custom],
  },
  {
    value: SectionConnectionTypes.Comment,
    label: SectionConnectionTypes[SectionConnectionTypes.Comment],
  },
  {
    value: SectionConnectionTypes.Review,
    label: SectionConnectionTypes[SectionConnectionTypes.Review],
  },
  {
    value: SectionConnectionTypes.Like,
    label: SectionConnectionTypes[SectionConnectionTypes.Like],
  },
];

export const SECTION_FIELD_TYPE_OPTIONS = [
  { value: SectionFieldType.Boolean, label: 'Boolean' },
  { value: SectionFieldType.ColorPicker, label: 'Color' },
  { value: SectionFieldType.Date, label: 'Date' },
  { value: SectionFieldType.DateTime, label: 'Date Time' },
  { value: SectionFieldType.File, label: 'File' },
  { value: SectionFieldType.Image, label: 'Image' },
  { value: SectionFieldType.ImageGallery, label: 'Image Gallery' },
  { value: SectionFieldType.Video, label: 'Video' },
  { value: SectionFieldType.VideoGallery, label: 'Video Gallery' },
  { value: SectionFieldType.KeyValue, label: 'Key/Value Pairs' },
  { value: SectionFieldType.MultiOption, label: 'Multi Option' },
  { value: SectionFieldType.MultilineText, label: 'Multiline Text' },
  { value: SectionFieldType.Number, label: 'Number' },
  { value: SectionFieldType.RichTextComplete, label: 'Rich Text (Complete)' },
  { value: SectionFieldType.RichTextSimple, label: 'Rich Text (Simple)' },
  { value: SectionFieldType.SectionLink, label: 'Section Link' },
  { value: SectionFieldType.SectionMultiLink, label: 'Section Multi Link' },
  { value: SectionFieldType.SingleOption, label: 'Single Option' },
  { value: SectionFieldType.Text, label: 'Text' },
  { value: SectionFieldType.TextArray, label: 'Text Array' },
];
