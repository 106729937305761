import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { EditPageLoading } from 'src/common/components';
import { ProjectForm } from './components';
import { useProjectForm } from './hooks';

type OrganizationsProps = RouteComponentProps<{
  organizationId: number;
  projectId: number;
}>;

export function ProjectEdit({ organizationId, projectId }: OrganizationsProps) {
  const { allLanguages, onDelete, organization, project, save, themes } = useProjectForm(organizationId, projectId);

  const loaded = !!organization && !!project && themes.length > 1;

  return loaded ? (
    <ProjectForm
      organization={organization}
      themes={themes}
      project={project}
      allLanguages={allLanguages}
      onDelete={onDelete}
      onSubmit={save}
    />
  ) : (
    <EditPageLoading />
  );
}
